import { title } from "../../material-kit-react.jsx";

const productStyle = (theme) => ({
  section: {
    padding: "3rem 0",
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      padding: "1rem 0",
    },
  },
  title: {
    ...title,
    marginBottom: "1rem",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none",
  },
  description: {
    color: "#999",
  },
  conditions: {
    color: "#999",
    opacity: "0.5",
    fontSize: "10px",
    textAlign: "left",
  },
  why: {
    fontSize: "1.6rem",
    fontWeight: "600",
    color: "#5da1eb",
    marginLeft: "9.75rem",
  },
  caintext: {
    left: "50%",
    transform: "translateX(-50%)",
    fontSize: "5.6rem",
    fontWeight: "800",
    textTransform: "uppercase",
    position: "absolute",
    WebkitBackgroundClip: "text",
    marginTop: "-1.5rem",
    backgroundImage: "linear-gradient(135deg,#1e3044,#65afff)",
    WebkitTextFillColor: "transparent",
    marginLeft: "0rem",
  },
  whywrap: {
    marginTop: "-3rem",
    opacity: "0.75",
    [theme.breakpoints.down("sm")]: {
      marginTop: "-3.5rem",
      marginBottom: "3rem",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "-3rem",
      marginBottom: "3rem",
    },
    // "&::after": {
    //   content: "''",
    //   borderBottom: "7px solid #000",
    //   width: "25%",
    //   position: "absolute",
    //   left: "50%",
    //   marginLeft: "-38.5%",
    //   bottom: "-8px",
    // },
  },
});

export default productStyle;

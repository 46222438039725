import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
import PanToolIcon from "@material-ui/icons/PanTool";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import ScatterPlotIcon from "@material-ui/icons/ScatterPlot";
import MeetingRoomIcon from "@material-ui/icons/MeetingRoom";
import BlurOn from "@material-ui/icons/BlurOn";
import DoneOutline from "@material-ui/icons/DoneOutline";
// core components
import GridContainer from "../components/Grid/GridContainer.jsx";
import GridItem from "../components/Grid/GridItem.jsx";
import InfoArea from "../components/InfoArea/InfoArea.jsx";

import productStyle from "../assets/jss/material-components/views/productStyle.jsx";

class ProductSection1 extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.section}>
        {/* <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={8}>
            <h2 className={classes.title}>Let's talk product</h2>
            <h5 className={classes.description}>
              This is the paragraph where you can write more details about your
              product. Keep you user engaged by providing meaningful
              information. Remember that by this time, the user is curious,
              otherwise he wouldn't scroll to get here. Add a button if you want
              the user to see more.
            </h5>
          </GridItem>
        </GridContainer> */}
        <div>
          <GridContainer
            spacing={8}
            direction="row"
            justify="center"
            alignItems="flex-start"
          >
            <GridItem
              xs={12}
              sm={6}
              data-sal="slide-right"
              data-sal-delay="300"
              data-sal-easing="ease"
            >
              <InfoArea
                title="Cain Hand sanitiser"
                description="Made with an 80% ethanol content our hand sanitiser with atomising spray is functional, efficient and is produced to meet EN 1500 certification standards. The high ethanol content meets WHO recommendations under public COVID-19 guidance. "
                icon={PanToolIcon}
                iconColor="rose"
                vertical
              />
            </GridItem>
            <GridItem
              xs={12}
              sm={6}
              data-sal="slide-left"
              data-sal-delay="500"
              data-sal-easing="ease"
            >
              <InfoArea
                title="A Unique Atomising Spray"
                description="Our Cain 500ml Sanitiser has a unique atomising spray dispenser for greater efficiency when compared to squeezable or free flowing dispensers. Cain 125ml and 250ml bottles are convenient and portable for regular hand application."
                icon={ScatterPlotIcon}
                iconColor="info"
                vertical
              />
            </GridItem>
            <GridItem className={classes.hidemobile} xs={12} sm={12} md={12}>
              <div
                className={classes.whywrap}
                data-sal="fade"
                data-sal-delay="900"
                data-sal-easing="ease"
              >
                <h1 className={classes.why}>Why</h1>
                <h1 className={classes.caintext}>Cain</h1>
              </div>
            </GridItem>
            <GridItem
              xs={12}
              sm={6}
              md={6}
              data-sal="slide-right"
              data-sal-delay="400"
              data-sal-easing="ease"
            >
              <InfoArea
                title="Wide Range of Applications"
                description="For use on surfaces to sterilise and create a hygienic environment. This spray has a wide range of applications including door surfaces, work tops, desks and other public surfaces. Ideal for retail environments, offices, homes and leisure industries including health and golf clubs. "
                icon={BlurOn}
                iconColor="primary"
                vertical
              />
            </GridItem>
            <GridItem
              xs={12}
              sm={6}
              md={6}
              data-sal="slide-left"
              data-sal-delay="600"
              data-sal-easing="ease"
            >
              <InfoArea
                title="Made in the UK"
                description="Made in the UK with the highest sanitary and quality standards, Cain Hand Sanitiser is strictly formulated to be protective in any scenario. Cain Sanitising Spray is 80% ethanol creating virus free hands and surfaces within seconds."
                icon={DoneOutline}
                iconColor="success"
                vertical
              />
            </GridItem>
          </GridContainer>
        </div>
      </div>
    );
  }
}

export default withStyles(productStyle)(ProductSection1);
